import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import OutsideClickHandler from "react-outside-click-handler";
import Chip from '../chip';
import FormFields from "../form-fields";
import { deepClone, flatTreeData } from '../../utils';
import { defaultMatchBarState, getFields } from './utils';
import { organizationState } from '../../atoms';
import { useRecoilValue } from 'recoil';
import debounce from 'lodash.debounce';
import ConditionalWrapper from '../conditional-wrapper';

const MasterSearch = forwardRef(({ options, onChange, onCancelChip, count = undefined, clearBtnText = "Clear All", visibileParameters = {}, parameterSettings, value, debounceTime = 1000, onClearAll, showClearAll, highlightedFilters = [], withMatchbar = true, withFilterBox = true, onClick, otherFields }, ref) => {

  // global state
  const { id: orgId } = useRecoilValue(organizationState);

  // local states
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [masterSearchData, setMasterSearchData] = useState(orgId ? { ...defaultMatchBarState, organizations: orgId } : { ...defaultMatchBarState })
  const [selectedChips, setSelectedChips] = useState([])

  // ref
  const displayedChipsInObjFormat = useRef({})
  const containerRef = useRef(null)
  const isMouseInsideFilterBox = useRef(false)

  // memo
  const visibleFields = useMemo(() => getFields({ visibileParameters, parameterSettings, otherFields }), [visibileParameters, parameterSettings, otherFields])

  const visibleFieldsKeyMapped = useMemo(() => visibleFields.reduce((acc, paramaterData) => {
    acc[paramaterData.id] = paramaterData
    return acc
  }, {}), [visibleFields])

  const filteredVisibleFields = useMemo(() => visibleFields.filter(fieldData => !parameterSettings?.[fieldData.id]?.hide), [visibleFields, parameterSettings])

  const onClickOutside = useCallback(() => {
    if (!isMouseInsideFilterBox.current) {
      setShowFilterBox(false)
    }
  }, [])

  const commonReset = useCallback(() => {
    if (!value) {
      setMasterSearchData({})
      setSelectedChips([])
    }
  }, [value])

  // 
  const onResetParams = useCallback((event) => {
    event.stopPropagation()
    commonReset()
    onClearAll && onClearAll()
  }, [onClearAll, commonReset])

  // handling clear all from outside
  useImperativeHandle(ref, () => ({
    onClearAll() {
      commonReset()
    }
  }))

  // 
  const onClickMatchBar = useCallback((e) => {
    e.stopPropagation();
    if (withFilterBox) {
      setShowFilterBox((showFilterBox) => !showFilterBox);
    }
    onClick && onClick(e)
  }, [withFilterBox, onClick])

  //on change of params data updating the displayed chips data
  const handleChipsDisplay = useCallback(({ type, changedData, fieldData }) => {
    let chips = [];
    const commonChipData = {
      type,
      subType: fieldData.subType
    }

    const modifiedOptions = deepClone(options)

    // this will be modified to support category select type  
    if (fieldData.subType === "multiSelectionWithSection") {
      modifiedOptions[type] = modifiedOptions?.[type]?.length ? modifiedOptions[type].map(parentOption => parentOption.buckets).flat() : []
    } else if (fieldData.subType === "multiSelectionWithTree") {
      modifiedOptions[type] = modifiedOptions?.[type]?.length ? flatTreeData(modifiedOptions[type]) : []
    }

    switch (fieldData.subType) {
      case "text":
        if (changedData.length >= 3) {
          chips.push({
            ...commonChipData,
            id: type,
            label: changedData
          });
        } else {
          chips = []
        }

        break;

      case "switch":
        if (changedData) {
          chips.push({
            ...commonChipData,
            ...fieldData,
            id: type,
            label: fieldData?.chipName || type
          });
        } else {
          chips = []
        }
        break;

      // supported for all subType of select and chips 
      default:
        (changedData || []).forEach((changedDataId) => {
          const selectedOption = modifiedOptions[type]?.find((option) => option.id === changedDataId) || {}
          if (selectedOption) {
            const chipData = {
              ...selectedOption,
              ...commonChipData,
              id: changedDataId,
              label: selectedOption.displayName || selectedOption.name,
              image: selectedOption.image || "",
            }
            chips.push(chipData);
          }
        })
        break;
    }

    displayedChipsInObjFormat.current[type] = chips
    const chipsArr = Object.values(displayedChipsInObjFormat.current).flat()
    setSelectedChips(chipsArr);
  }, [options])

  // handling value prop scenario
  useEffect(() => {
    if (value) {
      setMasterSearchData(value)
      displayedChipsInObjFormat.current = {}
      // updating chips according to value
      visibleFields.forEach(visibleFieldData => {
        const valueOfField = value?.[visibleFieldData.id] || defaultMatchBarState[visibleFieldData.id]
        handleChipsDisplay({ type: visibleFieldData.id, changedData: valueOfField, fieldData: visibleFieldData })
      })
    }
  }, [value, visibleFields, handleChipsDisplay])

  const onChangeWithDebounce = useCallback(debounce((...args) => {
    if (onChange) {
      onChange(...args)
    }
  }, debounceTime), [onChange])

  // on change of all params
  const onChangeParams = useCallback((changedValue, itemData, fieldData, added) => {
    const matchbarFieldType = fieldData.id
    const updatedSearchData = deepClone(masterSearchData)
    updatedSearchData[matchbarFieldType] = changedValue
    setMasterSearchData({ ...updatedSearchData })

    handleChipsDisplay({ type: matchbarFieldType, changedData: changedValue, fieldData })
    onChangeWithDebounce({ ...updatedSearchData }, itemData, fieldData, added)
  }, [masterSearchData, handleChipsDisplay, onChangeWithDebounce])

  // on delete chip from main input of matchbar
  const handleCancelChip = useCallback((event, canceledChip) => {
    event.stopPropagation();

    let changedData
    switch (canceledChip.subType) {

      case "switch":
        changedData = false
        break;

      case "text":
        changedData = ''
        break;


      default:
        changedData = masterSearchData?.[canceledChip.type]?.filter((chipId) => chipId !== canceledChip.id);
        break;
    }
    const fieldData = { ...canceledChip, id: canceledChip.type }
    onChangeParams(changedData, canceledChip, fieldData, false)
    onCancelChip && onCancelChip(canceledChip, changedData)
  }, [masterSearchData, onChangeParams, onCancelChip]);


  const getFieldDisabledStatus = useCallback((fieldData) => {
    return ((fieldData?.parent && fieldData?.enabledWhenParentSelected) ? !masterSearchData[fieldData.parent]?.length : false)
  }, [masterSearchData])

  const onMouseEnterFilterBox = useCallback(() => {
    isMouseInsideFilterBox.current = true
  }, [])

  const onMouseLeaveFilterBox = useCallback(() => {
    isMouseInsideFilterBox.current = false
  }, [])

  return (
    <ConditionalWrapper condition={withMatchbar} 
        Wrapper={(
          <MatchbarWrapper 
            clearBtnText={clearBtnText} count={count} handleCancelChip={handleCancelChip} 
            onClickMatchBar={onClickMatchBar} onClickOutside={onClickOutside} 
            onResetParams={onResetParams} selectedChips={selectedChips} showClearAll={showClearAll} 
            showFilterBox={showFilterBox} highlightedFilters={highlightedFilters} visibleFieldsKeyMapped={visibleFieldsKeyMapped} 
            onMouseEnterFilterBox={onMouseEnterFilterBox} onMouseLeaveFilterBox={onMouseLeaveFilterBox}
          />
        )}
    >
      <>
        {filteredVisibleFields.map(fieldData => (
          <FormFields
            className={`matchbar-field-${fieldData.id}`}
            key={fieldData.id}
            label={fieldData.label}
            placeholder={(getFieldDisabledStatus(fieldData) && fieldData?.disbledPlaceholder) ? fieldData.disbledPlaceholder : (fieldData?.placeholder || "Select")}
            type={fieldData?.subType || fieldData?.type}
            options={options[fieldData.id]}
            value={masterSearchData?.[fieldData.id]}
            onChange={(changedData, itemData, added) => onChangeParams(changedData, itemData, fieldData, added)}
            validations={fieldData?.validations}
            disabled={getFieldDisabledStatus(fieldData)}
            ref={containerRef}
          />
        ))}
      </>
    </ConditionalWrapper>
  );
})

const MatchbarWrapper = ({ children, onClickOutside, onClickMatchBar, count, selectedChips, handleCancelChip, showClearAll, clearBtnText, onResetParams, showFilterBox, highlightedFilters, visibleFieldsKeyMapped, onMouseEnterFilterBox,  onMouseLeaveFilterBox }) => {

  return (
    <div className="bimmatch-product-filter-container master-product-filter container">
      <OutsideClickHandler onOutsideClick={onClickOutside}>
        <div className="match-bar" onClick={onClickMatchBar} >
          <p className="match-header">Match</p>
          {!isNaN(count) ? (
            <div className="default-fixed-chip">
              <Chip
                // name={selectedChips.length ? totalProducts : totalProducts + " products"}
                name={count.toLocaleString()}
                iconSrc="/images/icon/product.svg"
              />
            </div>
          ) : (
            <></>
          )}
          {selectedChips.length ? (
            <div className="selected-chips-container">
              {selectedChips.map((chip) => (
                <Chip
                  key={chip.id}
                  name={chip.label}
                  iconSrc={chip?.image}
                  withCross={(typeof chip.withCross === "boolean") ? chip.withCross : true}
                  onClickCancel={(event) => handleCancelChip(event, chip)}
                  color={highlightedFilters.includes(chip.id) ? (visibleFieldsKeyMapped[chip.type]?.color || null) : null}
                />
              ))}
            </div>
          ) : (
            <></>
          )}
          {!!(showClearAll && selectedChips.length) && (
            <p className="clear-all" onClick={onResetParams}>
              {clearBtnText}
            </p>
          )}
        </div>
        <div className={`filter-box ${showFilterBox ? "" : "hide"}`} onMouseEnter={onMouseEnterFilterBox} onMouseLeave={onMouseLeaveFilterBox}>
          {children}
        </div>
      </OutsideClickHandler >
    </div>
  )
}

export default MasterSearch;